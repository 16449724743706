import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import preview from '../images/preview.png'

const PreviewPage = () => (
  <Layout>
    <img src={preview} />
  </Layout>
)

export default PreviewPage

// preview-zykksjdllskdjkskla